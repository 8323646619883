.courses_main {
  padding-top: 20px;
  background-color: #0d1625;
  min-height: calc(100vh - 130px);
}
.courses_wrapper {
  margin: 0 5%;
}
.courses_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #828282;
  margin-bottom: 10px;
  padding: 10px 0;
}
.courses_title_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
  position: relative;
}
.courses_title_container .title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: clamp(12px, 2.5vw, 16px);
  font-weight: bold;
  color: #fff;
  z-index: 1;
  text-align: left;
}
.courses_title_container .title > span {
  font-size: clamp(48px, 5vw, 80px);
  font-weight: 100;
  color: #fff;
  margin-bottom: 20px;
}
.courses_title_container .title > span:nth-of-type(2) {
  color: #ffb300;
}
.title_key_highlights {
  color: grey;
  font-size: clamp(10px, 2.5vw, 14px);
  font-weight: 200;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-transform: uppercase;
  width: 50%;
}
.title_key_highlights > span {
  color: #fff;
  margin-top: 15px;
  font-size: clamp(14px, 2.5vw, 16px);
  text-transform: none;
}
.title_key_highlights > span:nth-of-type(2) {
  color: #ffb300;
}
.c2_kh {
  margin: 25px 0;
  text-align: left;
}
.courses_title_container .title_img {
  width: 70%;
  height: 100%;
  aspect-ratio: 4/3;
  position: absolute;
  right: 0;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
  }
}
.c2_tc {
  min-height: 600px;
}
.courses_options_wrapper {
  display: flex;
  justify-content: center;
  background-color: #0e243e;
}
.courses_options_wrapper > div {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.courses_options_wrapper > div > div {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  border-right: 1px solid #fff;
  border-width: 0.1px;
  padding: 20px 0px;
  cursor: pointer;
}
.courses_options_wrapper > div > div:nth-child(odd) {
  width: 30%;
}
.courses_options_wrapper > div > div:nth-child(2) {
  width: 40%;
}
.courses_options_wrapper > div > span {
  color: #ffb300;
  font-weight: 400;
  padding: 20px 0px;
  width: 90%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  line-height: 20px;
}
.courses_options_wrapper > div:first-child {
  width: 60%;
}
.courses_options_wrapper > div:last-child {
  width: 40%;
}

.courses_options_wrapper button:hover {
  background-color: #e9e9e9;
}

.enquire_button {
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  background-color: #ffb300;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.payment_wrapper {
  color: #ffffff;
  padding: 20px;
}
.payment_wrapper > div:first-child {
  font-size: 24px;
  font-weight: 500;
}
.payment_info {
  margin: 25px 0;
  line-height: 25px;
  font-size: 20px;
}
.payment_info > div:last-child {
  margin-top: 20px;
}
.copied_pop_up {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  font-size: 14px;
  background-color: rgba(255, 179, 0, 0.7);
  color: #fff;
  border-radius: 5px;
  z-index: 100;
}
.courses_info_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 20px 40px;
  background-color: #fff;
  text-align: left;
}
.courses_info_wrapper h2 {
  font-size: clamp(20px, 2.5vw, 32px);
  font-weight: 300;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.courses_info_wrapper > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  flex-wrap: wrap;
}
.courses_info_wrapper div > span {
  display: flex;
  width: 50%;
  margin: 20px 0;
  font-weight: 400;
  font-size: clamp(12px, 2.5vw, 16px);
}
.courses_info_wrapper div > span > label {
  font-weight: 500;
  > div {
    font-weight: 400;
    margin-top: 10px;
    display: flex;
    line-height: 20px;
  }
}

.courses_details_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 20px;
  padding: 20px;
  color: #fff;
  text-align: left;
}
.courses_details_wrapper h2 {
  font-size: clamp(24px, 2.5vw, 32px);
  font-weight: 300;
  letter-spacing: 4px;
  text-transform: uppercase;
}
.courses_details_wrapper div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
}
.courses_details_wrapper div > span {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin: 20px 0;

  > label {
    font-size: clamp(24px, 2.5vw, 32px);
    font-weight: 400;
    color: #ffb300;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  > ul > li {
    margin: 10px 0;
  }
  li::marker {
    color: #ffb300;
  }
}

.register_now {
  color: #ffb300;
  font-size: 22px;
  font-weight: 400;
  width: 50% !important;
  align-items: center !important;
  margin: 20px 0;
}
.courses_details_note {
  text-align: left;
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  margin: 20px 0 50px;
  > div {
    font-size: 12px;
    margin: 35px 0;
  }
}

@media (max-width: 991px) {
  .courses_options_wrapper {
    flex-direction: column;
    text-align: left;
  }
  .courses_options_wrapper > div > div {
    border-right: none;
    border-bottom: 1px solid #fff;
  }
  .courses_options_wrapper > div:first-child,
  .courses_options_wrapper > div:last-child {
    width: calc(100% - 40px);
    flex-direction: column;
    align-items: flex-start;
    padding: 0 20px;
  }
  .courses_options_wrapper > div > div:nth-child(odd),
  .courses_options_wrapper > div > div:nth-child(2) {
    width: 100%;
  }
  .courses_options_wrapper > div > span {
    width: auto;
    gap: 10px 30px;
    flex-wrap: wrap;
  }
}
@media (max-width: 768px) {
  .courses_title_container .title > img {
    width: 175px;
  }
  .courses_title_container .title_img {
    width: 60%;
  }
  .courses_info_wrapper {
    padding: 15px;
  }
  .courses_info_wrapper div > span {
    width: 100%;
    margin: 10px 0;
  }
  .courses_details_wrapper {
    padding: 0px;
    margin: 15px;
  }
  .courses_details_wrapper div > span {
    width: 100%;
    margin: 10px 0;
    > label {
      margin-bottom: 0px;
    }
  }

  .register_now {
    width: 100% !important;
  }
}
@media (max-width: 600px) {
  .courses_title_container .title {
    max-width: 90%;
  }
  .c2_tc {
    min-height: auto;
  }
  .title_key_highlights {
    width: 100%;
  }
  .courses_title_container .title_img {
    height: 45%;
  }
}
