.home_main_wrapper {
  min-height: calc(100vh - 110px);
  width: 100%;
  display: flex;
  background-color: #0d1625;
  color: white;
  padding: 10px 0;
  /* overflow: hidden; */
}
.image_grid {
  position: relative;
  padding: 20px 25px 0 0;
  width: fill-available;
  width: webkit-fill-available;
  width: moz-available;
}

@media (max-width: 1024px) {
  .home_main_wrapper {
    flex-direction: column;
    justify-content: center;
  }
  .image_grid {
    padding: 20px;
  }
}

@media (max-width: 1024px) {
  .image_grid {
    padding: 10px;
  }
}
