.home_left_panel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 50px;
  width: calc(30% - 100px);
  max-width: 400px;
  height: 100%;
  position: sticky;
  top: 70px;
  z-index: 2;
}
.logo {
  max-width: 70%;
  position: relative;
  display: flex;
  min-width: 215px;
  > div {
    font-size: 12px;
    text-align: left;
    position: absolute;
    bottom: 15px;
    left: 15px;
    letter-spacing: 2.555px;
  }
}
.home_left_panel p {
  margin-top: 20px;
  font-size: 16px;
  text-align: left;
  letter-spacing: 1.5px;
  line-height: 20px;
}
.register_container {
  display: flex;
  flex-direction: column;
  margin: 40px 0;
  width: 100%;
}
.register_container > h5 {
  width: 60%;
  text-align: left;
  color: #ffb300;
  font-weight: 500;
}
.register_container > input,
textarea {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  background-color: rgb(68, 68, 68);
  color: rgb(129, 129, 129);
  resize: none;
}

.register_container button {
  width: 50%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 2px;
  background-color: #ffb300;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.register_container button:hover {
  background-color: #e9e9e9;
}
.register_container.affiliate_div {
  margin-top: 30px;
}
.register_container input::placeholder {
  color: rgb(129, 129, 129);
}

.register_container input:nth-child(5) {
  height: 50px;
}

@media (max-width: 1024px) {
  .home_left_panel {
    width: fit-content;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
    max-width: none;
    top: 20px;
    z-index: 1;
    padding: 10px 20px;
  }
  .home_left_panel > div {
    width: 48%;
    gap: 4%;
  }
}

@media (max-width: 600px) {
  .home_left_panel {
    flex-direction: column;
    padding: 10px;
  }
  .home_left_panel > div {
    width: fit-content;
  }
  .register_container {
    min-width: 80%;
  }
}
