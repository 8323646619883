.aboutUs_main_div {
  background-color: #0d1625;
  color: #fff;
  padding: 0 5vw 5vw;
  text-align: left;
}
.aboutUs_main_div > img {
  margin: 25px 0 0 25px;
}
.aboutUs_title {
  margin: 25px 25px 50px;
}
.t1 {
  color: #ffb300;
  font-size: clamp(48px, 7.5vw, 150px);
  font-family: "Ariel", sans-serif;
}
.aboutUs_description {
  font-size: 16px;
  font-size: clamp(14px, 2.5vw, 18px);
  margin: 20px 0;
}
.aboutUs_people_title {
  font-size: clamp(24px, 2.5vw, 36px);
  color: #ffb300;
  margin: 25px;
  font-weight: 400;
}
.aboutUs_people_title::first-letter {
  font-size: clamp(28px, 2.5vw, 44px);
}
.aboutUs_people_description {
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.aboutUs_people_card {
  display: flex;
  flex-direction: row;
  margin: 25px;
}
.aboutUs_people_img {
  height: 200px;
  /* object-fit: cover; */
  aspect-ratio: 1/1;
}
.aboutUs_people_info {
  font-size: clamp(14px, 2.5vw, 18px);
  margin-left: 10px;
  text-align: left;
}
.aboutUs_people_info::first-letter {
  text-transform: uppercase;
  font-size: 32px;
  font-weight: 500;
  color: #ffb300;
}
@media screen and (max-width: 768px) {
  .aboutUs_people_card {
    flex-direction: column;
  }
  .aboutUs_people_img {
    object-fit: contain;
  }
  .aboutUs_people_info {
    margin-left: 0;
    margin-top: 10px;
  }
}
@media screen and (max-width: 600px) {
  .aboutUs_main_div > img {
    margin-left: 0px;
    margin-left: 0px;
  }
  .aboutUs_title,
  .aboutUs_people_title {
    margin: 25px 0;
  }
  .aboutUs_people_card {
    flex-direction: column;
    margin: 25px 0;
  }
}
