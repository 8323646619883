.faq_main_div {
  background-color: #0d1625;
  color: white;
  line-height: 20px;
  min-height: calc(100vh - 110px);
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}
.faq_title {
  text-align: left;
  padding: 0 5vw 8vw;
  display: flex;
  flex-direction: column;
}
.faq_sub {
  margin: 20px 0;
  font-weight: 500;
  color: #ffb300;
  font-size: clamp(48px, 7.5vw, 150px);
  display: flex;
  min-height: 100px;
  align-items: center;
}
.faq_main {
  font-size: clamp(16px, 2.5vw, 20px);
  font-weight: 700;
}
.faq_div {
  padding: 0 5vw 5vw;
}
.faq_question_div {
  text-align: left;
  margin-bottom: 10px;
  border-bottom: 4px solid lightgray;
  letter-spacing: 2px;
}
.faq_question_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: clamp(16px, 2.5vw, 20px);
  font-weight: 500;
  margin: 20px 0;
}
.faq_question_title > span {
  cursor: pointer;
  transition: transform 0.5s;
}
.faq_question_description {
  font-size: clamp(12px, 2.5vw, 16px);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.3s ease-in-out;
}
.show_faq {
  max-height: 600px;
  opacity: 1;
  margin: 10px 0;
}
