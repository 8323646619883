.main_layout_wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.header_container {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #0d1625;
  height: 60px;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(117, 117, 117, 0.2);
}
.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 25%;
}
.header_button_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  > button {
    background-color: transparent;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: #f5f5f5;
      box-shadow: 0 0 10px 1px rgb(117, 117, 117);
      background-color: rgb(20, 20, 20);
    }
  }
}
