.sidebar_container {
  position: fixed;
  display: flex;
  flex-direction: row;
  top: 20px;
  right: 30px;
}

.ham_button {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  color: white;
  scale: 1.75;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  background-color: #ffb300;
  color: white;
  min-width: 320px;
  height: 100vh;
  position: relative;
  transition: all 0.25s;
  z-index: 5;
}
.sidebar > .offcanvas-body {
  width: 100%;
}
.close_button {
  position: absolute;
  top: 25px;
  left: 10px;
  background-color: transparent;
  box-shadow: none;
  border: none;
  cursor: pointer;
  color: white;
}

.sidebar_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 40px 20px;
  color: black;
}

.sidebar_item {
  font-size: 28px;
  font-weight: 500;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.sidebar_item:hover {
  color: #ffffff;
}

.active::after {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  transform: rotate(45deg);
  background-color: black;
  margin-left: 10px;
  position: absolute;
  right: 10px;
}

.sub_item {
  font-size: 16px;
  line-height: 20px;
}

.sub_category {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.expanded {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}

/* OffCanvas styles */
.offcanvas {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 300px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  background-color: #ffb300;
}

.offcanvas.show {
  transform: translateX(0);
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}
