.contact_main_div {
  display: flex;
  justify-content: center;
  height: calc(100vh - 110px);
  background-color: #0d1625;
  color: #fff;
}
.contact_info_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: clamp(16px, 2.5vw, 24px);
  padding: 5vw;
  line-height: 35px;
}
.info2 {
  color: #ffb300;
  font-size: clamp(12px, 2.5vw, 18px);
}
