.not_found_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
  font-size: 30px;
  > a {
    color: #000;
    text-decoration: none;
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: #000;
    color: #fff;
    margin-top: 20px;
  }
}
