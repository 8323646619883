.image_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.image_sc {
  width: calc(50% - 10px);
  height: auto;
  object-fit: cover;
  border-radius: 12px;
  transition: all 0.5s;
}
.image_sc:hover {
  cursor: pointer;
  scale: 1.075;
  z-index: 1;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.image_preview_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin: auto;
}
.image_preview_container button {
  position: absolute;
  top: 50px;
  right: 40px;
  background-color: transparent;
  color: white;
  border: none;
  cursor: pointer;
}
.image_preview {
  object-fit: cover;
  margin: 5%;
  max-width: 98vw;
  max-height: 98vh;
}
@media screen and (max-width: 600px) {
  .image_sc {
    width: calc(100% - 10px);
  }
}
